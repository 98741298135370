/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/************************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React from 'react';

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import "./style-welcome.css";
import useWelcomeCtr from "../../controller/use-welcom-ctr";
import Footer from "../widget/footer/footer";
import Header from "../../screen/widget/index";
import { useHistory } from "react-router";
import "react-multi-carousel/lib/styles.css";
import maa from "../../resources/images/solveB/maa.svg";
import ip from "../../resources/images/solveB/ip.svg";
import restructuring from "../../resources/images/solveB/restructuring.svg";
import bootstraping from "../../resources/images/solveB/bootstraping.svg";
import white from "../../resources/images/solveB/white.svg";
import early2 from "../../resources/images/solveB/early-f.svg";
import legal from "../../resources/images/solveB/legal-ip.svg";
import asset from "../../resources/images/solveB/ip-mon.svg";
import copyright from "../../resources/images/solveB/copyright.svg";
import other from "../../resources/images/solveB/other.svg";
import later2 from "../../resources/images/solveB/later-f.svg";
import alternative from "../../resources/images/solveB/alternative.svg";
import sponsors from "../../resources/images/spons.svg";
import early from "../../resources/images/early-funding.svg";
import later from "../../resources/images/later-funding.svg";
import ma from "../../resources/images/manda.svg";
import strategic from "../../resources/images/strategic-partner.svg";
import more from "../../resources/images/more.svg";
import howitwork from "../../resources/images/how-it-work.svg";
import { Link } from "react-router-dom";

export default function Welcome() {
  const {
    understoodCoockies,
    setUnderstoodCoockies,
  } = useWelcomeCtr();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsiveForwho = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const history = useHistory();

  return (
    <div>
      <Header />
      <section className="HomeGetStarted d-flex align-items-stretch">
        <div className="container mx-auto d-flex">
          <div className="moveyoursoftware">
            <h1 className="title">
              {/*parse(showDynamicTextDrupal(text, accueil.TITLE, "title"))*/}
              Strategic initiatives for software publishers
            </h1>
            <p>
              {/*parse(showDynamicTextDrupal(text, accueil.TITLE, "body"))*/}
              {/* Devinsider gives you access to peers, investors and partner
              programs. Whether it’s about business development or managing your
              corporate life cycle, there is always an Insider that helps you
              move forward. */}
            </p>
            {/* <a
              onClick={(e) => {
                e.preventDefault();
                getStarted();
              }}
              className='getStartedLink'
            >
              Get started
              <span className='itsfree'>(It's free!)</span>
            </a> */}

            <div className="hubSoftwareVendor">
              <div className="container mx-auto">
                {/*<h1 className="title">
                  {parse(showDynamicTextDrupal(text, accueil.TITLE_CENTER, "title"))}
                </h1>
                <div className="explicationvendor">
                  {parse(showDynamicTextDrupal(text, accueil.TITLE_CENTER, "body"))}
                  </div>*/}
                <ul className="listsoftwarevendor">
                  <li>
                    <img src={early} alt="" />
                    <h2 className="title">
                      Early stage funding
                      {/* {parse(showDynamicTextDrupal(text, accueil.COMMUNITY, "title"))} */}
                    </h2>
                    <div className="textforitems">
                      {/*parse(showDynamicTextDrupal(text, accueil.COMMUNITY, "body"))*/}
                      Match with early-stage software investors based on
                      your company profile and preferences.
                    </div>
                    <Link to="/landing-find-inv" className="learnMore">
                      More info <img src={more} alt="" />
                    </Link>
                  </li>
                  <li>
                    <img src={later} alt="" />
                    <h2 className="title">
                      {/*parse(showDynamicTextDrupal(text, accueil.ECOSYSTEM, "title"))*/}
                      Later stage funding
                    </h2>
                    <div className="textforitems">
                      {/*parse(showDynamicTextDrupal(text, accueil.ECOSYSTEM, "body"))*/}
                      Match with investors to cover funding needs beyond A
                      round.
                    </div>
                    <Link to="/landing-find-inv" className="learnMore">
                      More info <img src={more} alt="" />
                    </Link>
                  </li>
                  <li>
                    <img src={ma} alt="" />
                    <h2 className="title">
                      M&A
                      {/* {parse(showDynamicTextDrupal(text, accueil.INVESTORS, "title"))} */}
                    </h2>
                    <div className="textforitems">
                      {/*parse(showDynamicTextDrupal(text, accueil.INVESTORS, "body"))*/}
                      Buy and sell assets among Devinsider’s global community of
                      software publishers and institutional investors.
                    </div>
                    <a className="learnMore linkDisable">Coming soon</a>
                  </li>
                  <li>
                    <img src={strategic} alt="" />
                    <h2 className="title">
                      Strategic partnerships
                      {/* {parse(showDynamicTextDrupal(text, accueil.RESELLERS, "title"))} */}
                    </h2>
                    <div className="textforitems">
                      {/*parse(showDynamicTextDrupal(text, accueil.RESELLERS, "body"))*/}
                      Identify the most relevant technology partnerships that
                      help ensure the growth of your software business.
                    </div>
                    <Link to="/landing-find-prog" className="learnMore">
                      More info <img src={more} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="howItWorks">
        {/* <div className='container mx-auto tit'>
          <h1 className='title'>How it works</h1>
        </div> */}
        <div className="container mx-auto">
          {/*<h1 className="title">{textBlue && textBlue.title}</h1>*/}
          <div className="textHow">
            {/* <p>
              <span className='icon-signup'></span>
              <br />
              {textBlue && textBlue.field_lien1}
            </p>
            <p>
              <span className='icon-add-software'></span>
              <br />
              {textBlue && textBlue.field_lien2}
            </p>
            <p>
              <span className='icon-talk'></span>
              <br />
              {textBlue && textBlue.field_lien3}
            </p>
            <a
              href=''
              className='getStartedLink'
              onClick={(e) => {
                e.preventDefault();
                getStarted();
              }}
            >
              Get started
            </a> */}
            <h2>
              Access matchmaking <br/>features to connect <br/>with the best opportunities
            </h2>
          </div>
          <div className="imagehow">
            <img src={howitwork} alt="" />
          </div>
        </div>
      </section>
      <section className="solvebusiness">
        <div className="container mx-auto">
          <h1 className="title">Solve your business challenges</h1>
          <ul className="listLabelLink">
            <li>
              <img src={ip} />
              <p>Ip asset sale</p>
            </li>
            <li>
              <img src={restructuring} />
              <p>Restructuring</p>
            </li>
            <li>
              <img src={bootstraping} />
              <p>Bootstrapping</p>
            </li>
            <li>
              <img src={white} />
              <p>White labelling</p>
            </li>
            <li>
              <img src={early2} />
              <p>Early stage funding</p>
            </li>
            <li>
              <img src={legal} />
              <p>Legal IP protection</p>
            </li>
            <li>
              <img src={asset} />
              <p>IP asset monetization</p>
            </li>
            <li>
              <img src={copyright} />
              <p>Copyright VS . patent</p>
            </li>
            <li>
              <img src={other} />
              <p>Other asset monetization</p>
            </li>
            <li>
              <img src={later2} />
              <p>Later stage funding</p>
            </li>
            <li>
              <img src={alternative} />
              <p>Alternative financing</p>
            </li>
            <li>
              <img src={maa} />
              <p>Mergers and acquisitions</p>
            </li>
          </ul>
          <Link to="/community/home" className="joinConversationLink">
            Join the community
          </Link>
        </div>
      </section>

      {/* <section className='getStartedHome'>
        <div className='container mx-auto'>
          <h1 className='title mt-3'>
            {parse(
              showDynamicTextDrupal(text, accueil.TEXT_GET_STARTED, 'title')
            )}
          </h1>
          
          <p>
            Boost the growth of your software business. Join Devinsider and get
            access to strategic <br />
            business opportunities.
          </p>
          <Link to='/account-choices' className='getStartedLink'>
            Get started
          </Link>
        </div>
      </section> */}
      <section className="mainSpons">
        <div className="container mx-auto">
          <h1 className="title">Devinsider sponsors:</h1>
          <img className="imgSpons" src={sponsors} alt="" />
          {/* <Link to='/get-started-sponsors' className='getStartedLink'>
            View all sponsors
          </Link> */}
        </div>
      </section>
      {/*<section className="hwoyousee">
        <div className="container mx-auto">
          <div className="textforwho">
            <h1 className="title">
              {parse(showDynamicTextDrupal(text, accueil.YOU_USE, "title"))}
            </h1>
            <p>{parse(showDynamicTextDrupal(text, accueil.YOU_USE, "body"))}</p>
          </div>
          <Carousel responsive={responsiveForwho} className="partner">
            {ItemPub?.map((element: ItemPub, index: number) => (
              <div className="howItems" key={index}>
                <div className="logoHomeText">[Logo]</div>
              </div>
            ))}
          </Carousel>
        </div>
        </section>*/}
        {/* Footer */}
        <Footer />
        {/* Dev #47259 Créer une bannière d'information concernant les Cookies */}
        {understoodCoockies === false && (
            <div className='banner-cookies'>
              <p>
                {/* This website uses essential cookies to ensure its proper
            functioning. */}
                This website uses essential cookies to ensure its proper
                functioning. <br />
                This website doesn't use third party cookies.
              </p>
              <a
                  href='javascript:;'
                  className='btn btn-devinsider'
                  onClick={() => {
                    sessionStorage.setItem('cbasics', 'true');
                    setUnderstoodCoockies(true);
                  }}
              >
                {' '}
                I understand
              </a>
            </div>
        )}
      </div>
  );
}
